import type { User } from '~/interfaces';
import { userRepository } from '~/repositories/userRepository';

export function useFollowUsers() {
  const { $api } = useNuxtApp();
  const userRepo = userRepository($api);
  const interestingUsers = ref<User[]>([]);
  const similarUsers = ref<User[]>([]);
  const isLoadingFollowInteresting = ref(false);
  const isLoadingFollowSimilar = ref(false);

  onMounted(async () => {
    const interestingUsersFromResponse = (await userRepo.getInterestingUsers()).formattedUsers;
    interestingUsers.value = interestingUsersFromResponse.map((user: User) => ({
      ...user,
      followingBack: false
    }));
    const similarUsersFromResponse = await userRepo.getSimilarUsers();
    similarUsers.value = similarUsersFromResponse.map((user: User) => ({
      ...user,
      followingBack: false
    }));
  });
  const followAllInteresting = async () => {
    isLoadingFollowInteresting.value = true;
    const followPromises = interestingUsers.value.map((user: User) => {
      user.followingBack = true;
      return userRepo.handleUserFollowing(false, user.id);
    });
    await Promise.all(followPromises);
    isLoadingFollowInteresting.value = false;
  };
  const followAllSimilar = async () => {
    isLoadingFollowSimilar.value = true;
    const followPromises = similarUsers.value.map((user: User) => {
      user.followingBack = true;
      return userRepo.handleUserFollowing(false, user.id);
    });
    await Promise.all(followPromises);
    isLoadingFollowSimilar.value = false;
  };
  return {
    interestingUsers,
    similarUsers,
    isLoadingFollowInteresting,
    isLoadingFollowSimilar,
    followAllInteresting,
    followAllSimilar
  };
}
